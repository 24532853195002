import React,{useEffect,useState} from 'react';
import {setResumeTemplateData,bindEdit} from '../../functional_lib/common_lib';
import { public_url } from '../../ApiUrl';

//import './comp_resm_6.css'// only for reff

export const Comp_Resm_6=(props)=>{
    const [resumeData,setResumeData]=useState({});
    const [defaultImg,setDefaultImg]=useState("");
    useEffect(()=>{
        if(typeof props.setProfile.candidate_master!=="undefined"){
            setResumeData(setResumeTemplateData(props.setProfile));
      
          }
      },[props.setProfile])
      
      useEffect(()=>{
        
        if(typeof resumeData.basic!=="undefined"){
            bindEdit();
            if(resumeData.basic.gender==="male"){
                setDefaultImg("/assets/resm_thumb/male_rems.jpg");
              }else{
                setDefaultImg("/assets/resm_thumb/female_rems.jpg");
              }
        }

},[resumeData])

    return(
        <>
        {typeof resumeData.basic!=="undefined"?
        <div class="container newejobeeresume01">
        <div class="header row align-items-center mb-2">
          <div class="col-md-2 my-2">
          {typeof resumeData.basic.profile_pic!=="undefined"?
          <img src={public_url+resumeData.basic.profile_pic} alt="Profile Picture" class="img-fluid resm_pic" />
            :<img src={defaultImg} alt="Profile Picture" class="img-fluid resm_pic"/>}
          </div>
          <div class="col-md-3 .nameheading" style={{"text-align":"center"}}>
            <h2 class="white">{resumeData.basic.first_name} {resumeData.basic.last_name}</h2>
            <h4 class="text-center editable white" e_type="text" e_key="f" e_id="ft">Job Title</h4>
          </div>
           <div class="col-md-7 address"> <p class="mb-0"> 
           <span><i class="fas fa-map-marker"></i></span>{'\u00A0'}{resumeData.contact.address}<br/>
              <span><i class="fas fa-phone"></i></span>{'\u00A0'} {resumeData.contact.mobile} {'\u00A0'}|<br/>
              <span> <i class="far fa-envelope"></i></span>{'\u00A0'}  {resumeData.contact.email} {'\u00A0'}  
              </p>
              </div>
        </div>
      
        <div class="row mt-4">
          <div class="col-12">
            <h2 class="headding-title editable" e_type="text" e_key="f" e_id="ft">Summary</h2>
            <p class="editable" e_type="textarea" e_key="f" e_id="ft">
              To leverage my creativity and analytical skills as a marketing
              executive in a dynamic organization, contributing to innovative
              campaigns and driving brand success while enhancing my professional
              growth in the marketing industry.
            </p>
          </div>
        </div>
      
        <div class="row mt-4">
          <div class="col-12">
            <h2 class="headding-title">Experience</h2>
            {resumeData.experience.map((item,index)=>{
                return <> <h5>{item["jobrole_name"]} - {item["duration"]}</h5>
            <p>{item["company_name"]}</p></>
            })}
          </div>
        </div>
      
        <div class="row mt-4">
          <div class="col-6">
            <h2 class="headding-title">Education</h2>
            {resumeData.education.map((item,index)=>{
                return <h5><strong>{item["degree"]}</strong><br/>{item["university"]},<strong>{item["duration"]}</strong></h5>
            })}
          </div>
          <div class="col-6">
            <h2 class="headding-title">Skills</h2>
            <div class="row">
              <div class="col-md-6">
                <p class="editable" e_type="text" e_key="f" e_id="ft">
                    {resumeData.skills.map((item,index)=>{
                return <>{index!==0?", "+item:item}</>
                })
               }</p>
              </div>
            </div>
          </div>
        </div>
      
        <div class="row mt-4">
          <div class="col-md-6">
            <h2 class="headding-title">Language Known</h2>
            <p e_type="textarea" e_key="f" e_id="ft" class="editable"> {resumeData.language.map((item,index)=>{
                return <>{index!==0?", "+item:item}</>
             })}</p>
          </div>
          <div class="col-md-6 prefrence">
            <h2 class="headding-title">Preference</h2>
            {resumeData.preference.map((item,index)=>{
                return <><p>Work Type: {item["work"]}</p>
                <p>Timing: {item["job_time"]}</p>
                <p>Job Shift: {item["job_shift"]}</p>
                <p>Pref. City: {item["city_name"]}</p></>
            })}
            
          </div>
        </div>
      
        <div class="row mt-4 signature">
          <div class="col-12">

          <div class="footer d-flex justify-content-between">
                <p class="editable" e_type="text" e_key="f" e_id="ft">Date: 06/08/2024</p>
                <p class="editable " e_type="text" e_key="f" e_id="ft">SIGNATURE</p>
            </div>
            {/* <h2 class="headding-title">Signature</h2> */}
           
          </div>
        </div>
    </div>:''}
    </>    
    );
}
export default Comp_Resm_6;