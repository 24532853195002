import React,{useEffect,useState} from 'react';
import {setResumeTemplateData,bindEdit} from '../../functional_lib/common_lib';
import { public_url } from '../../ApiUrl';

export const Comp_Resm_3_New=(props)=>{
    const [resumeData,setResumeData]=useState({});
    const [defaultImg,setDefaultImg]=useState("");
    useEffect(()=>{
        if(typeof props.setProfile.candidate_master!=="undefined"){
            setResumeData(setResumeTemplateData(props.setProfile));
          }
      },[props.setProfile])

      useEffect(()=>{
        
        if(typeof resumeData.basic!=="undefined"){
            bindEdit();
            if(resumeData.basic.gender==="male"){
                setDefaultImg("/assets/resm_thumb/male_rems.jpg");
              }else{
                setDefaultImg("/assets/resm_thumb/female_rems.jpg");
              }
        }

},[resumeData])   

    return(
        <div class="container-fluid mt-111 newresumedivbox">
             {typeof resumeData.basic!=="undefined"?
            <div class="container11">
        <div class="row mx-4 py-3">
            <div class="col-8 bg-dark d-flex text-center namecontainer row11">
            {typeof resumeData.basic.profile_pic!=="undefined"?
                <div class='col'>
                    <img src={public_url+resumeData.basic.profile_pic} class="rounded-circle resm_pic"/>
                </div>
                :  <div class='col'>
                <img src={defaultImg} class="rounded-circle resm_pic" style={{height: "auto", width: "150px"}}/>
            </div>}
                <div class="col my-auto">
                    <h2>{resumeData.basic.first_name} {resumeData.basic.last_name}</h2>
                <p class="editable" e_type="text" e_key="f" e_id="ft">Job Title</p>
                </div>
                
            </div>
            {/* <div class="col-1 my-auto">
                </div> */}
            <div class="col-4 my-auto">
                <div class="contact-info mt-3 ms-3">
                    <p><h4><i class="fas fa-phone mr-5"></i>{resumeData.contact.mobile}</h4></p>
                    <p><h4><i class="fas fa-envelope "><span class="ml-5">{resumeData.contact.email}</span></i></h4></p>
                    <p><h4><i class="fas fa-map-marker-alt "><span class="ml-5">{resumeData.contact.address}</span></i></h4></p>
                </div>
            </div>
        </div>

     
        <div class="row mx-4 aboutrow">
            <div class="col-3 my-auto text-center">
                <h4>About Me</h4>
            </div>
            <div class="col-9 my-auto">
                <p class="editable" e_type="textarea" e_key="f" e_id="ft">"To leverage my creativity and analytical skills as a marketing executive in a dynamic organization, contributing to innovative campaigns and driving brand success while enhancing my professional growth in the marketing industry."</p>
            </div>
        </div>


        <div class="row mx-4 exprow">
            <div class="col-3 my-auto text-center">
                <h4>Experience</h4>
            </div>
            <div class="col-9">
            {resumeData.experience.map((item,index)=>{
                return <div class="mt-3">
                    <h5>{item["company_name"]} <span class="float-right">{item["duration"]}</span></h5>
                    <p><strong>{item["jobrole_name"]}</strong></p>
                    
                        <p class="editable" e_type="text" e_key="f" e_id="ft">Promoted and marketed pharmaceutical products to healthcare professionals.</p>
                        <p class="editable" e_type="text" e_key="f" e_id="ft">Handled sales in multiple territories, including Ujjain, Agar, Tarana, etc.</p>
                    <hr/>
                </div>
            })}
               
            </div>
        </div>

        <div class="row mx-4 edurow">
            <div class="col-3 my-auto  text-center">
                <h4>Education</h4>
            </div>
            <div class="col-9">
            {resumeData.education.map((item,index)=>{
                return <p><strong>{item["duration"]}</strong><br/>{item["degree"]},{item["university"]}
                </p>
            })}
            </div>
        </div>


        <div class="row mx-4 text-center langrow">
            <div class="col-6">
                <h4>Language</h4>
                <p e_type="textarea" e_key="f" e_id="ft" class="editable">{resumeData.language.map((item,index)=>{
                    return<>{index!==0?", "+item:item}</>
                })}
                    </p>
            </div>
            <div class="col-6">
                <h4>Expertise</h4>
                    <p class="editable" e_type="text" e_key="f" e_id="ft">
                    {resumeData.skills.map((item,index)=>{
                        return <>{index!==0?", "+item:item}</>
                    })}
                    </p>
            </div>
        </div>
        <div class="footer d-flex justify-content-between mx-4  mt-4">
                <p class="editable" e_type="text" e_key="f" e_id="ft">Date: 06/08/2024</p>
                <p class="editable " e_type="text" e_key="f" e_id="ft">SIGNATURE</p>
            </div>
        </div>
        :''}
    </div>
    );
}
export default Comp_Resm_3_New;