import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { alljobshift_service, alljobtype_service, allworktype_service, complete_preference_detail, getallcityservice } from '../../ApiServices'
import { useLocation, useNavigate } from 'react-router-dom'

const Preferences = () => {


    const navigate = useNavigate();
    const location = useLocation();

    const [formdata, setformdata] = useState({
        jobcity: '',
        worktype: '',
        jobtype: '',
        jobshift:'',
    })
    const [errors, seterrors] = useState({});
    const [jobcityoption, setjobcityoption] = useState([])
    const [worktypeoption, setworktypeoption] = useState([])
    const [jobtypeoption, setjobtypeoption] = useState([])
    const [jobshiftoption, setjobshiftoption] = useState([]);

    useEffect(() => {
        const id = new URLSearchParams(location.search).get('id');
        if (id == '' || id == null) {
            navigate('/login');
        }

        if (localStorage.getItem('candidate_id')==null) {
            navigate('/login');
        }

        if (localStorage.getItem('candidate_is_preference_details')=='true') {
            navigate('/')
        }

        getjobcity();
        getjobtype();
        getworktype();
        getjobshift();
    }, [])


    const formvalidate = () => {
        const newerrors = {}

        // if (!formdata.jobcity) {
        //     newerrors.jobcity = "Please Select Preferred City"
        // }
        if(!formdata.city_name){
            newerrors.city_name="Job Preferred City Require ";
        }

        if (!formdata.jobtype) {
            newerrors.jobtype = "Please Select Preferred Job Type"
        }

        if (!formdata.worktype) {
            newerrors.worktype = "Please Select Preferred Work Type"
        }

        if (!formdata.jobshift) {
            newerrors.jobshift = "Please Select Preferred Job Shift"
        }

        seterrors(newerrors);
        return Object.keys(newerrors).length === 0
    }


    const getjobcity = () => {
        getallcityservice().then((data) => {
            const mappedoptions = data.data.map((v) => ({
                value: v.id,
                label: v.name
            }))
            setjobcityoption(mappedoptions)
        }).catch((error) => { })
    }

    const getworktype = () => {
        allworktype_service().then((data)=>{
            const mappedoptions = data.data.data.map((v) => ({
                value: v.id,
                label: v.name
            }))
            setworktypeoption(mappedoptions)
        })
    }

    const getjobtype = () =>{
        alljobtype_service().then((data)=>{
            const mappedoptions = data.data.data.map((v) => ({
                value: v.id,
                label: v.name
            }))
            setjobtypeoption(mappedoptions)
        }).catch((error)=>{

        })
    }

    const getjobshift = () =>{
        alljobshift_service().then((data)=>{
            const mappedoptions = data.data.data.map((v) => ({
                value: v.id,
                label: v.name
            }))
            setjobshiftoption(mappedoptions)
        })
    }

    
    const handleChange = (event) => {
        const { name, value } = event.target
        seterrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        setformdata({
            ...formdata,
            [name]: value
        })
    }

    const HandleSelectChange = (value, action) => {
        setformdata({
            ...formdata,
            [action.name]: value
        })
        seterrors((preverror) => ({
            ...preverror,
            [action.name]: ''
        }))
    }

    const submit = () => {
        if (formvalidate()) {
            complete_preference_detail(formdata).then((data) => {
                localStorage.setItem('candidate_is_preference_details', 'true');
                localStorage.setItem('candidate_is_profile_completed', 'true');
                navigate('/')
            }).catch((error) => { })
        }
    }

    return (
        <div className='main-login'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <img className='w-100 w-100-mob mt-auto' src="/assets/images/personal-detail.png" alt="EJOBEE" />
                    </div>
                    <div className="col-lg-5 min-heigh-mob">
                        <div className="login-box d-flex flex-column justify-content-center h-100 h-100-mob">
                            <h2>Preference Details</h2>
                            <p>Fill preference Details For Wich Job that Fits You</p>
                            <div className="login-form mt-2">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-lg-12 mt-2">
                                        <input
                    type="text"
                    name='city_name'
                    value={formdata.city_name}
                    class="form-control"
                    placeholder="Job Preference City"
                    onChange={handleChange}
                  />

                                            {/* <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Preferred Job City"
                                                isSearchable={true}
                                                name="jobcity"
                                                value={formdata.jobcity}
                                                onChange={HandleSelectChange}
                                                options={jobcityoption}
                                            /> */}
                                            {errors.city_name && <span className='text-danger'>{errors.city_name}</span>}
                                        </div>

                                        <div className="col-lg-12 mt-2">
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Preferred Work Type"
                                                isSearchable={true}
                                                name="worktype"
                                                value={formdata.worktype}
                                                onChange={HandleSelectChange}
                                                options={worktypeoption}
                                            />
                                            {errors.worktype && <span className='text-danger'>{errors.worktype}</span>}
                                        </div>

                                        <div className="col-lg-12 mt-2">
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Preferred Job Type"
                                                isSearchable={true}
                                                value={formdata.jobtype}
                                                name="jobtype"
                                                onChange={HandleSelectChange}
                                                options={jobtypeoption}
                                            />
                                            {errors.jobtype && <span className='text-danger'>{errors.jobtype}</span>}
                                        </div>

                                        <div className="col-lg-12 mt-2">
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                placeholder="Preferred Job Shift"
                                                isSearchable={false}
                                                value={formdata.jobshift}
                                                name="jobshift"
                                                onChange={HandleSelectChange}
                                                options={jobshiftoption}
                                            />
                                            {errors.jobshift && <span className='text-danger'>{errors.jobshift}</span>}
                                        </div>

                                    </div>
                                </div>
                                <div onClick={submit} className='btn btn-primary mt-3 w-100'>Continue</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Preferences